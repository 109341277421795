import { useStaticQuery, graphql, Link } from 'gatsby'

/** @jsx jsx */
import { jsx } from 'theme-ui'

import Button from './button'

const Banner = (props) => {
    const data = useStaticQuery(pageQuery)
    const siteName = data.site.siteMetadata.siteTitle

    return (
        <div sx={{
            bg: "primary",
            color: "white",
            p: 4,
            borderRadius: 1,
        }}>
            <span sx={{fontSize: 3, display: "block", mb: 2,}}>Référencez votre établissement sur {siteName} !</span>
            Bénéficiez de plus de visibilité pour votre référencement local ciblé et qualitatif. Créez une page améliorée, boostez votre listing, obtenez de nouveaux contenus multimédia et plus encore !
            <br/>
            <Link to="/referencement" 
                className="button"
                data-button="banner"
                data-position={props.position}
                sx={{
                    display: "inline-block",
                    color: "primary",
                    bg: "white",
                    px: 3, py: 2,
                    mt: 3,
                    borderRadius: 0,
                    border: "1px solid white",
                    textDecoration: "none",
                    ":hover": {
                        color: "secondary"
                    }
            }}>
                Enregistrez votre entreprise
            </Link>
        </div>
    )
}

export default Banner


const pageQuery = graphql`
  query bannerQuery {
    site {
      siteMetadata {
        siteTitle
      }
    }
  }
`